<template>
    <div>
      <h1>Page non trouvée</h1>
      <p>Désolé, cette page n'existe pas.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFoundPage',
  };
  </script>