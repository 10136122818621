import { createWebHistory, createRouter } from 'vue-router'
import NotFoundPage from './pages/NotFoundPage.vue'

import HomeView from './pages/HomeView.vue'
import MagiciansView from './pages/MagiciansView.vue'
import GalleryView from './pages/GalleryView.vue'
import LessonsView from './pages/LessonsView.vue'
import LevelsView from './pages/LevelsView.vue'
import PricesView from './pages/PricesView.vue'
import InternshipsView from './pages/InternshipsView.vue'
// import AboutView from './pages/AboutView.vue'
import EventsView from './pages/EventsView.vue'
import ContactView from './pages/ContactView.vue'

const routes = [
  { path: '/', component: HomeView },
  
  { path: '/magiciens', component: MagiciansView },
  { path: '/galerie', component: GalleryView },
  { path: '/cours', component: LessonsView },
  { path: '/niveaux', component: LevelsView },
  { path: '/tarifs', component: PricesView },
  { path: '/stages', component: InternshipsView },
  //   { path: '/a-propos', component: AboutView },
  { path: '/agenda', component: EventsView },
  { path: '/contact', component: ContactView },

  { path: '/:pathMatch(.*)*', component: NotFoundPage }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router